import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { GridDataResult } from '@progress/kendo-angular-grid';
import {
    State,
    toDataSourceRequestString,
    translateDataSourceResultGroups,
} from '@progress/kendo-data-query';

@Injectable()
export class FactureFournisseursService extends BehaviorSubject<GridDataResult[]> {
    public loading: boolean;
    constructor(private http: HttpClient) {
        super([]);
    }

    private BASE_URL = environment.apiUrl +'FactureFournisseurs';
    private data: any[] = [];
    public state: State = {
        skip: 0,
        take: 20,
        filter: { filters: [], logic: 'or' },
        group: [],
        sort: []
    };

    public read() {
        if (this.data.length) {
            return super.next(this.data);
        }

        this.fetch()
            .pipe(
                tap(data => {
                    this.data = data;
                })
            )
            .subscribe(data => {
                super.next(data);
            });
    }

    public fetch(dataItem?: any, action: string = ''): Observable<any> {
        this.loading = true;
        switch (action) {
            case '': {
                const queryStr = `${toDataSourceRequestString(this.state)}`;
                const hasGroups = this.state.group && this.state.group.length;

                return this.http.get(`${this.BASE_URL}?${queryStr}`).pipe(
                    // Process the response.
                    map(({ data, total }: GridDataResult): GridDataResult => {
                        return {
                            data: hasGroups ? translateDataSourceResultGroups(data) : data,
                            total: total
                        };
                    }
                    ),
                    tap(() => this.loading = false)
                    );
            }
        }
    }

    queryAll(st?: any): Observable<GridDataResult> {
        const state = Object.assign({}, st);
        delete state.skip;
        delete state.take;

        this.state = state;
        return this.fetch();
    }

    create(data) {    
        return this.http.post(this.BASE_URL, data, {responseType: 'text'})
      }

  getData(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/' + id)
  }

  roles(search): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.BASE_URL + '/Roles/' + search)
  }

  filter(search): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.BASE_URL + '/Filter/' + search)
  }

  edit(data) {
    return this.http.put(this.BASE_URL, data)
  }

  delete(id) {
    return this.http.delete(this.BASE_URL + '/' + id)
  }
}