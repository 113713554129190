import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuardService } from './services/auth-guard.service';
import { VidetutoComponent } from './views/videtuto/videtuto.component';
import { DashboardrestoComponent } from './views/dashboardresto/dashboardresto.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'videotuto',
        component: VidetutoComponent,
        data: {
          title: 'Video Tutorial'
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'dashboardresto',
        component: DashboardrestoComponent,
        data: {
          title: 'Dashboard Resto'
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'administration',
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdminstatrationModule)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./views/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./views/stock/stock.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'caisse',
        loadChildren: () => import('./views/caisse/caisse.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'compte',
        loadChildren: () => import('./views/comptes/compte.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'vente',
        loadChildren: () => import('./views/vente/vente.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'achat',
        loadChildren: () => import('./views/achat/achat.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'rapport',
        loadChildren: () => import('./views/rapport/rapport.module').then(m => m.RapportModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
