import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private loginPath = environment.apiUrl + "identity/login";
  private registerPath = environment.apiUrl + "identity/register";
  private decodedToken;

  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    return this.http.post(this.loginPath, data)
  }

  register(data): Observable<any> {
    return this.http.post(this.registerPath, data)
  }

  logout() {
    localStorage.clear()
  }

  saveToken(token) {
    localStorage.setItem('token', token)
  }

  getToken() {
    return localStorage.getItem('token')
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const userRoles = this.decodedToken.role as Array<string>;
    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
        return;
      }
    });

    return isMatch;

  }

  isAuthenticated() {
    if (this.getToken()) {

      this.decodedToken = this.decodeToken();

      if (this.decodedToken.exp === undefined) {
        return false;
      }

      const date = new Date(0);
      let tokenExp = date.setUTCSeconds(this.decodedToken.exp);

      if (tokenExp.valueOf() > new Date().valueOf()) {

        return true

      }

    }
    return false;
  }

  getEntreprisesId() {
    
    if (this.getToken()) {

      this.decodedToken = this.decodeToken();

      if (this.decodedToken.EntreprisesId === undefined) {
        return "";
      }
      return this.decodedToken.EntreprisesId

    }

    return "";
  }

  getUserName() {
    
    if (this.getToken()) {

      this.decodedToken = this.decodeToken();

      if (this.decodedToken.unique_name === undefined) {
        return "";
      }
      return this.decodedToken.unique_name

    }

    return "";
  }

  decodeToken(): any {
    var token = localStorage.getItem('token');
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }

  }
}
