import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString, State, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AccueilPService extends BehaviorSubject<any[]> {
  public loading: boolean;
  private catPath = environment.apiUrl + '';
  constructor(private http: HttpClient) {
    super([]);
  }

  apiName(apiname: string){
    this.catPath = environment.apiUrl + apiname;
  }
  private data: any[] = [];
  public state: State = {
    skip: 0,
    take: 20,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
  };

  public read(debut: Date, fin: Date) {
    if (this.data.length) {
      return super.next(this.data);
    }

    this.fetch(debut, fin)
      .pipe(
        tap(data => {
          this.data = data;
        })
      )
      .subscribe(data => {
        super.next(data);
      });
  }

  public fetch(debut: Date, fin: Date, dataItem?: any, action: string = ''): Observable<any> {
    this.loading = true;
    switch (action) {
      case '': {
        const queryStr = `${toDataSourceRequestString(this.state)}`;
        const hasGroups = this.state.group && this.state.group.length;

        return this.http.get(`${this.catPath + "/GetPaiementCaisse?debut=" + debut.toDateString() + "&fin=" + fin.toDateString()}&${queryStr}`).pipe(
          // Process the response.
          map(({ data, total }: GridDataResult): GridDataResult => {
            return {
              data: hasGroups ? translateDataSourceResultGroups(data) : data,
              total: total
            };
          }
          ),
          tap(() => this.loading = false)
        );
      }
    }
  }

  queryAll(debut: Date, fin: Date, st?: any): Observable<GridDataResult> {
    const state = Object.assign({}, st);
    delete state.skip;
    delete state.take;

    this.state = state;
    return this.fetch(debut, fin);
  }

}