import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { ToastrModule } from "ngx-toastr";

import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';

import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';


import { load, IntlModule } from '@progress/kendo-angular-intl';
// Load the default locale initially
import '@progress/kendo-angular-intl/locales/fr/all';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { MessageService } from '@progress/kendo-angular-l10n';

import { MyMessageService } from './services/my-message.service';
import { GridModule,  ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeFr, 'fr-FR');

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';


import { EntreprisesService } from './services/entreprises.service';
import { DataService } from './services/DataService';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ClientsService } from './services/ClientsService';
import { UtilisateursService } from './services/UtilisateursService';
import { ArticlesService } from './services/ArticlesService';
import { CategoriesService } from './services/CategoriesService';
import { FournisseursService } from './services/FournisseursService';
import { MouvementStockService } from './services/MouvementStockService';
import { EtatStockServices } from './services/EtatStockService';
import { AjustementsService } from './services/AjustementsService';
import { RubriquesService } from './services/RubriquesService';
import { ComptesService } from './services/ComptesService';
import { CaissesService } from './services/CaissesService';
import { SoldeComptesService } from './services/SoldeComptesService';
import { CompteCService } from './services/CompteCService';
import { CompteFService } from './services/CompteFService';
import { ProformaService } from './services/ProformaService';
import { BccService } from './services/BccService';
import { BlcService } from './services/BlcService';
import { BlfService } from './services/BlfService';
import { BrfService } from './services/BrfService';
import { BrcService } from './services/BrcService';
import { FactureFournisseursService } from './services/FactureFournisseursService';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { VidetutoComponent } from './views/videtuto/videtuto.component';

// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';
import { DashboardrestoComponent } from './views/dashboardresto/dashboardresto.component';
import {AvoireCService} from './services/AvoireCService';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ToastrModule.forRoot(),
    TelerikReportingModule,
    ReactiveFormsModule,
    HttpClientModule,
    GridModule,
    IntlModule,
    DialogsModule,
    WindowModule,
    ExcelModule,
    PDFModule,
    DropDownsModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    LayoutModule,
    YouTubePlayerModule,
    NotificationModule,
    // Specify AvatarModule as an import
    AvatarModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    VidetutoComponent,
    DashboardrestoComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
  {provide: LOCALE_ID, useValue: 'fr-FR'},
  { provide: MessageService, useClass: MyMessageService },
  AuthService,
  DataService,
  CategoriesService,
  EntreprisesService,
  SoldeComptesService,
  ClientsService,
  CaissesService,
  RubriquesService,
  ComptesService,
  FournisseursService,
  MouvementStockService,
  AjustementsService,
  EtatStockServices,
  ArticlesService,
  UtilisateursService,
  CompteCService,
  CompteFService,
  ProformaService,
  BccService,
  BlcService,
  BlfService,
  BrfService,
  BrcService,
    AvoireCService,
  FactureFournisseursService,
  AuthGuardService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true
  }],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
