import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Entreprises } from '../models/Entreprises';

@Injectable({
  providedIn: 'root'
})

export class EntreprisesService {
  private catPath = environment.apiUrl + 'Entreprises';
  constructor(private http: HttpClient) { }

  create(data): Observable<Entreprises> {    
    return this.http.post<Entreprises>(this.catPath, data)
  }

  get(): Observable<Array<Entreprises>> {
    return this.http.get<Array<Entreprises>>(this.catPath)
  }

  filter(search): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.catPath + '/Filter/' + search)
  }

  getData(id): Observable<Entreprises> {
    return this.http.get<Entreprises>(this.catPath + '/' + id)
  }

  edit(data) {
    return this.http.put(this.catPath, data)
  }

  delete(id) {
    return this.http.delete(this.catPath + '/' + id)
  }
}