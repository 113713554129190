import { MessageService } from '@progress/kendo-angular-l10n';
import { Injectable } from "@angular/core";

const messages = {
'kendo.grid.groupPanelEmpty':'Faites glisser un en-tête de colonne et déposer ici pour grouper par cette colonne.',
'kendo.grid.noRecords':'Aucun enregistrement disponible.',
'kendo.grid.pagerFirstPage':'Aller à la première page',
'kendo.grid.pagerPreviousPage':'Aller à la page précédente',
'kendo.grid.pagerNextPage':'Aller à la page suivante',
'kendo.grid.pagerLastPage':'Aller à la dernière page',
'kendo.grid.pagerPage':'Page',
'kendo.grid.pagerOf':'de',
'kendo.grid.pagerItems':'éléments',
'kendo.grid.pagerItemsPerPage':'éléments par page',
'kendo.grid.filter':'Filtrer',
'kendo.grid.filterEqOperator':'Est égal à',
'kendo.grid.filterNotEqOperator':'N’est pas égal à',
'kendo.grid.filterIsNullOperator':'Est nulle',
'kendo.grid.filterIsNotNullOperator':'N’est pas nulle',
'kendo.grid.filterIsEmptyOperator':'Est vide',
'kendo.grid.filterIsNotEmptyOperator':'N’est pas vide',
'kendo.grid.filterStartsWithOperator':'Commence par',
'kendo.grid.filterContainsOperator':'Contient',
'kendo.grid.filterNotContainsOperator':'Ne contient pas',
'kendo.grid.filterEndsWithOperator':'Se termine par',
'kendo.grid.filterGteOperator':'Est supérieur ou égal à',
'kendo.grid.filterGtOperator':'Est supérieur à',
'kendo.grid.filterLteOperator':'Est inférieur ou égal à',
'kendo.grid.filterLtOperator':'Est inférieur',
'kendo.grid.filterIsTrue':'est vrai',
'kendo.grid.filterIsFalse':'est fausse',
'kendo.grid.filterBooleanAll':'(Tout)',
'kendo.grid.filterAfterOrEqualOperator':'Est postérieur ou égal à',
'kendo.grid.filterAfterOperator':'Est postérieur',
'kendo.grid.filterBeforeOperator':'Est antérieur',
'kendo.grid.filterBeforeOrEqualOperator':'Est antérieur ou égal à',
'kendo.grid.filterFilterButton':'Filtrer',
'kendo.grid.filterClearButton':'Effacer filtre',
'kendo.grid.filterAndLogic':'Et',
'kendo.grid.filterOrLogic':'Ou',
'kendo.grid.loading':'Chargement',
'kendo.grid.sort':'Tri',
'kendo.grid.columnMenu':'Menu des colonnes',
'kendo.grid.columns':'Colonnes',
'kendo.grid.lock':'Bloquer',
'kendo.grid.unlock':'Débloquer',
'kendo.grid.sortAscending':'Tri croissant',
'kendo.grid.sortDescending':'Tri décroissant',
'kendo.grid.columnsApply':'Appliquer',
'kendo.grid.columnsReset':'Réinitialiser',
'kendo.grid.sortable':'Sortable',
'kendo.grid.sortedAscending':'Sorted ascending',
'kendo.grid.sortedDescending':'Sorted descending',
'kendo.grid.sortedDefault':'Not sorted',
'kendo.autocomplete.noDataText':'Aucun enregistrement trouvé',
'kendo.autocomplete.clearTitle':'Effacer',
'kendo.autocomplete.combobox':'',
'kendo.combobox.noDataText':'Aucun enregistrement trouvé',
'kendo.combobox.clearTitle':'Effacer',
'kendo.autocomplete.dropdownlist':'',
'kendo.dropdownlist.noDataText':'Aucun enregistrement trouvé',
'kendo.dropdownlist.clearTitle':'Effacer',
'kendo.autocomplete.multiselect':'',
'kendo.multiselect.noDataText':'Aucun enregistrement trouvé',
'kendo.multiselect.clearTitle':'Effacer',
'kendo.datepicker.today':'Aujourd\'hui',
'kendo.datepicker.toggle':'Afficher le calendrier',
'kendo.datetimepicker.dateTab':'Date',
'kendo.datetimepicker.dateTabLabel':'Onglet de date',
'kendo.datetimepicker.timeTab':'Heures',
'kendo.datetimepicker.timeTabLabel':'Onglet des heures',
'kendo.datetimepicker.toggle':'Afficher le calendrier',
'kendo.datetimepicker.accept':'Valider',
'kendo.datetimepicker.acceptLabel':'Valider',
'kendo.datetimepicker.cancel':'Annuler',
'kendo.datetimepicker.cancelLabel':'Annuler',
'kendo.datetimepicker.now':'Maintenant',
'kendo.datetimepicker.nowLabel':'Sélectionner maintenant',
'kendo.datetimepicker.today':'Aujourd\'hui',
'kendo.numerictextbox.increment':'Augmenter la valeur',
'kendo.numerictextbox.decrement':'Diminuer la valeur',
'kendo.window.closeTitle':'Close',
'kendo.window.restoreTitle':'Restore',
'kendo.window.maximizeTitle':'Maximize',
'kendo.window.minimizeTitle':'Minimize',
};

@Injectable()
export class MyMessageService extends MessageService {
  public get(key: string): string {
    return messages[key];
  }
}
