import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {

    if (this.authService.isAuthenticated()) {
      const roles = next.data['roles'] as Array<string>;

      if (roles) {

        if (this.authService.roleMatch(roles)) {

          return true;

        } else {



          //this.toastrService.error("you are not authorize");
          this.router.navigate(["videotuto"]);

        }
      }

      return true;

    } else {
      this.router.navigate(["login"]);
      return false;
    }
  }
}
